import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col, Form } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact" />
    
    <Container className="my-4 singleCol">
      <h1>Call or Email Today to Schedule a FREE Consultation</h1>
      <Row>
        <Col lg={6}>
          <p className="mt-1 mb-0">Phone: 415-825-0350 (9am-6pm Daily)</p>
          <p>Email: <a href="mailto:Jim.Kautz@comcast.net">Jim.Kautz@comcast.net</a></p>
        </Col>
        <Col lg={6}>
          <Form 
            action="https://formsubmit.co/Jim.Kautz@comcast.net"
            onSubmit="submit"
            method="POST"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="_honey" />
            <input type="hidden" name="_captcha" value="false" />
            <p hidden>
            <label>
                Don’t fill this out: <input name="bot-field" />
            </label>
            </p>
            <Row>
              <Col md={6}>
                <Form.Group className="mt-2">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" name="first-name" required />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-2">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name="last-name" required />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mt-2">
              <Form.Label>Email address</Form.Label>
              <Form.Control 
                  required
                  type="email" 
                  name="email" 
              />
              </Form.Group>

              <Form.Group className="mt-2">
                  <Form.Label>Phone <em><small>(Optional)</small></em></Form.Label>
                  <Form.Control 
                    type="text" 
                    name="phone" 
                  />
              </Form.Group>
              <Form.Group className="mt-2">
                <Form.Label>Project Description</Form.Label>
                <Form.Control 
                    required
                    as="textarea" 
                    name="project-description"
                    rows="3"
                  />
              </Form.Group>
              <button type="submit" className="mt-2 btn btn-outline-dark">Submit Now</button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
)

export default SecondPage
